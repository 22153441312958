export default {
    props: {
        title: { type: String, default: 'Title' },
        icon: { type: String, default: 'far fa-check-circle' },
        subTitle: { type: String, default: 'SubTitle' },
    },
    created() {
        if (this.title == 'Title') {
            this.title = 'Request received'
        }
        if (this.subTitle == 'SubTitle') {
            this.subTitle =
        'Check your email for instructions on how to complete the sign up process.'
        }
    },
}
